import type { Client } from '@urql/vue';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) return; // Ensures this runs only in the browser

  const id = to.params.id;
  const $apiClient = useNuxtApp().$apiClient as Client;
  const result = await $apiClient.query(`
    query Publication($id: ID!) {
      publication(id: $id) {
        id
      }
    }
  `, { id }).toPromise();
  const data = result.data?.publication;
  if (result.error) {
    sentryError(`There was an error fetching the publication #${id} in the middleware`, result.error);
    return navigateTo('/500'); // Redirect to 500 and send sentry if there was an error
  } else if (!data?.id) {
    return navigateTo('/404'); // Redirect if the publication don't exist
  }
});
